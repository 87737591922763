import axios, { AxiosResponse } from "axios";

const BASE_URL = "https://displayback.saroz.top";
// const BASE_URL = 'http://localhost:3000';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export interface SeatInfo {
  id?: number;
  student_name?: string;
  student_id?: string;
  student_type_name?: string;
  department_type_name?: string;
  teacher_name?: string;
  teacher_id?: string;
  house_type_id?: number;
  research_direction?: string;
  gender?: string;
  birthday?: string;
  is_graduate_supervisor?: boolean | string;
  status?: number;
  number?: number;
  campus_id?: number;
  building_id?: number;
  floor_id?: number;
  house_id?: number;
}

export interface UpdateSeatResponse {
  code: number;
  data: any;
  message: string;
}

export interface SeatSearchResponse {
  code: number;
  data: {
    number: number;
    status: number;
    house_name: string;
    floor_name: string;
    building_name: string;
    campus_name: string;
    department_type_name: string;
    student_type_name?: string;
    teacher_name?: string;
    research_direction?: string;
    gender?: string;
    birthday?: string;
    is_graduate_supervisor?: boolean;
  } | null;
  message: string;
}

export async function getCampuses(params?: {
  house_type_id?: number;
}): Promise<AxiosResponse<API.NameIdList>> {
  return axiosInstance.get<API.NameIdList>("/public/campus/get", { params });
}

export async function getBuildings(params: {
  campus_id?: number;
  house_type_id?: number;
}): Promise<AxiosResponse<API.NameIdList>> {
  return axiosInstance.get<API.NameIdList>("/public/building/get", { params });
}

export async function getFloors(params: {
  building_id?: number;
  house_type_id?: number;
}): Promise<AxiosResponse<API.NameIdList>> {
  return axiosInstance.get<API.NameIdList>("/public/floor/get", { params });
}

export async function getHouses(params: {
  floor_id?: number;
  house_type_id?: number;
}): Promise<AxiosResponse<API.NameIdList>> {
  return axiosInstance.get<API.NameIdList>("/public/house/get", { params });
}

export async function getSeats(params: {
  house_id?: number;
  house_type_id?: number;
}): Promise<AxiosResponse<API.NameIdList>> {
  return axiosInstance.get<API.NameIdList>("/public/seat/get", { params });
}

export async function getStudentTypes(): Promise<
  AxiosResponse<API.NameIdList>
> {
  return axiosInstance.get<API.NameIdList>("/public/list/student_types");
}

export async function getDepartmentTypes(): Promise<
  AxiosResponse<API.NameIdList>
> {
  return axiosInstance.get<API.NameIdList>("/public/list/department_types");
}

export async function updateSeatInfo(
  data: SeatInfo
): Promise<AxiosResponse<UpdateSeatResponse>> {
  return axiosInstance.put<UpdateSeatResponse>("/public/seat/update", data);
}

export async function getSeatInfo(params: {
  student_name?: string;
  student_id?: string;
  teacher_name?: string;
  teacher_id?: string;
  house_type_id?: number;
}): Promise<AxiosResponse<SeatSearchResponse>> {
  return axiosInstance.get<SeatSearchResponse>("/public/search/student", {
    params,
  });
}

// 在文件顶部添加新的接口定义
export interface AddSeatInfo {
  id?: number;
  number?: number;
  house_id: number;
  house_type_id: number;
  student_name?: string;
  student_id?: string;
  student_type_name?: string;
  department_type_name?: string;
  teacher_name?: string;
  teacher_id?: string;
  research_direction?: string;
  gender?: "男" | "女";
  birthday?: string;
  is_graduate_supervisor?: boolean;
  position_name?: string;
  title_name?: string;
}

export interface AddSeatResponse {
  code: number;
  data: null;
  message: string;
}

// 在文件中添加新的函数
export async function addSeatInfo(
  data: AddSeatInfo
): Promise<AxiosResponse<AddSeatResponse>> {
  return axiosInstance.post<AddSeatResponse>("/public/seat/add", data);
}
