import React, { useState, useEffect } from 'react';
import '../styles/ExamInfoPage.scss';
import {
  getCampuses,
  getBuildings,
  getFloors,
  getHouses,
  getStudentTypes,
  getDepartmentTypes,
  updateSeatInfo,
  addSeatInfo,
  SeatInfo,
  AddSeatInfo,
  getSeats
} from '../services/api';

const ExamInfoPage: React.FC = () => {
  const [campuses, setCampuses] = useState<API.NameId[]>([]);
  const [buildings, setBuildings] = useState<API.NameId[]>([]);
  const [floors, setFloors] = useState<API.NameId[]>([]);
  const [houses, setHouses] = useState<API.NameId[]>([]);
  const [studentTypes, setStudentTypes] = useState<API.NameId[]>([]);
  const [departmentTypes, setDepartmentTypes] = useState<API.NameId[]>([]);
  const [seats, setSeats] = useState<API.NameId[]>([]);

  const [seatInfo, setSeatInfo] = useState<SeatInfo>({
    id: undefined,
    campus_id: undefined,
    building_id: undefined,
    floor_id: undefined,
    house_id: undefined,
    number: undefined,
    status: 1,
    student_name: undefined,
    student_id: undefined,
    student_type_name: undefined,
    department_type_name: undefined,
    teacher_name: undefined,
    teacher_id: undefined,
    house_type_id: 1, // 默认为学生座位
    research_direction: undefined,
    gender: undefined,
    birthday: undefined,
    is_graduate_supervisor: undefined
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // 新增 state 用于教师职务和职称
  const [positionName, setPositionName] = useState('');
  const [titleName, setTitleName] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [campusesData, studentTypesData, departmentTypesData] = await Promise.all([
          getCampuses({ house_type_id: seatInfo.house_type_id }),
          getStudentTypes(),
          getDepartmentTypes()
        ]);
        setCampuses(campusesData.data.data);
        setStudentTypes(studentTypesData.data.data);
        setDepartmentTypes(departmentTypesData.data.data);
      } catch (error) {
        setErrorMessage('加载初始数据失败，请刷新页面重试');
      }
    };
    fetchInitialData();
  }, [seatInfo.house_type_id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === 'house_type_id') {
      // 重置表单
      setSeatInfo({
        id: undefined,
        campus_id: undefined,
        building_id: undefined,
        floor_id: undefined,
        house_id: undefined,
        number: undefined,
        status: 1,
        student_name: undefined,
        student_id: undefined,
        student_type_name: undefined,
        department_type_name: undefined,
        teacher_name: undefined,
        teacher_id: undefined,
        house_type_id: Number(value),
        research_direction: undefined,
        gender: undefined,
        birthday: undefined,
        is_graduate_supervisor: undefined
      });
      
      // 重置相关状态
      setBuildings([]);
      setFloors([]);
      setHouses([]);
      setSeats([]);
      setErrorMessage('');
    } else {
      setSeatInfo(prev => ({
        ...prev,
        [name]: ['campus_id', 'building_id', 'floor_id', 'house_id', 'house_type_id'].includes(name)
          ? (value === '' ? undefined : Number(value))
          : value
      }));
    }
  };

  const handleCampusChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const campusId = Number(e.target.value);
    setSeatInfo(prev => ({ ...prev, campus_id: campusId, building_id: undefined, floor_id: undefined, house_id: undefined, number: undefined, id: undefined }));
    try {
      const { data } = await getBuildings({ campus_id: campusId, house_type_id: seatInfo.house_type_id });
      setBuildings(data.data)
    } catch (error) {
      setErrorMessage('加载楼栋数据失败');
    }
  };

  const handleBuildingChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const buildingId = Number(e.target.value);
    setSeatInfo(prev => ({ ...prev, building_id: buildingId, floor_id: undefined, house_id: undefined, number: undefined, id: undefined }));
    try {
      const { data } = await getFloors({ building_id: buildingId, house_type_id: seatInfo.house_type_id });
      setFloors(data.data)
    } catch (error) {
      setErrorMessage('加载楼层数据失败');
    }
  };

  const handleFloorChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const floorId = Number(e.target.value);
    setSeatInfo(prev => ({ ...prev, floor_id: floorId, house_id: undefined, number: undefined, id: undefined }));
    try {
      const { data } = await getHouses({ floor_id: floorId, house_type_id: seatInfo.house_type_id });
      setHouses(data.data)
    } catch (error) {
      setErrorMessage('加载房间数据失败');
    }
  };

  const handleHouseChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const houseId = Number(e.target.value);
    setSeatInfo(prev => ({ ...prev, house_id: houseId, number: undefined, id: undefined }));
    if (seatInfo.house_type_id === 1) {
      try {
        const { data } = await getSeats({ house_id: houseId, house_type_id: seatInfo.house_type_id });
        setSeats(data.data);
      } catch (error) {
        setErrorMessage('加载座位数据失败');
      }
    }
  };

  const handleSeatChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const seatId = Number(e.target.value);
    const selectedSeat = seats.find(seat => seat.id === seatId);
    if (selectedSeat) {
      setSeatInfo(prev => ({
        ...prev,
        id: seatId,
        number: Number(selectedSeat.name)
      }));
    }
  };

  const validateSeatInfo = (info: SeatInfo | AddSeatInfo): boolean => {
    const requiredFields: (keyof (SeatInfo | AddSeatInfo))[] = [
      'house_id', 'department_type_name'
    ];

    if (info.house_type_id === 1) {
      requiredFields.push('number', 'id', 'student_name', 'student_id', 'student_type_name', 'teacher_name');
    } else {
      requiredFields.push('teacher_name', 'teacher_id', 'research_direction', 'gender', 'birthday');
    }

    for (const field of requiredFields) {
      if (info[field] === undefined || info[field] === '') {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    if (!validateSeatInfo(seatInfo)) {
      setErrorMessage('请填写所有必要的信息');
      setIsLoading(false);
      return;
    }

    try {
      if (seatInfo.house_type_id === 1) {
        // 学生工位更新
        const updatedSeatInfo = {
          ...seatInfo,
          is_graduate_supervisor: seatInfo.is_graduate_supervisor === "true"
        };

        const response = await updateSeatInfo(updatedSeatInfo as SeatInfo);
        if (response.data.code === 0) {
          alert('学生座位信息更新成功');
        } else {
          alert(response.data.message || '更新失败，请稍后再试');
        }
      } else {
        // 教师工位添加
        const newTeacherSeat: AddSeatInfo = {
          house_id: seatInfo.house_id!,
          house_type_id: seatInfo.house_type_id!,
          teacher_name: seatInfo.teacher_name!,
          teacher_id: seatInfo.teacher_id!,
          department_type_name: seatInfo.department_type_name!,
          research_direction: seatInfo.research_direction!,
          gender: seatInfo.gender as '男' | '女',
          birthday: seatInfo.birthday!,
          is_graduate_supervisor: seatInfo.is_graduate_supervisor === "true",
          position_name: positionName || undefined,  // 如果为空字符串，则设为 undefined
          title_name: titleName || undefined  // 如果为空字符串，则设为 undefined
        };

        const response = await addSeatInfo(newTeacherSeat);
        if (response.data.code === 0) {
          alert('教师座位信息添加成功');
        } else {
          alert(response.data.message || '添加失败，请稍后再试');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(`操作失败：${error.message}`);
      } else {
        setErrorMessage('操作失败，发生未知错误');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="exam-info-page">
      <div className="container">
        <h1 className="title">{seatInfo.house_type_id === 1 ? '学生工位信息采集' : '教师工位信息采集'}</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label className="input-label" htmlFor="house_type_id">工位类型</label>
            <select
              className="input-field"
              id="house_type_id"
              name="house_type_id"
              value={seatInfo.house_type_id}
              onChange={handleInputChange}
              required
            >
              <option value={1}>学生工位</option>
              <option value={2}>教师工位</option>
            </select>
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="campus_id">校区</label>
            <select
              className="input-field"
              id="campus_id"
              name="campus_id"
              value={seatInfo.campus_id?.toString() || ''}
              onChange={handleCampusChange}
              required
            >
              <option value="">请选择校区</option>
              {campuses.map(campus => (
                <option key={campus.id} value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="building_id">楼栋</label>
            <select
              className="input-field"
              id="building_id"
              name="building_id"
              value={seatInfo.building_id?.toString() || ''}
              onChange={handleBuildingChange}
              required
              disabled={!seatInfo.campus_id}
            >
              <option value="">请选择楼栋</option>
              {buildings.map(building => (
                <option key={building.id} value={building.id}>{building.name}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="floor_id">楼层</label>
            <select
              className="input-field"
              id="floor_id"
              name="floor_id"
              value={seatInfo.floor_id?.toString() || ''}
              onChange={handleFloorChange}
              required
              disabled={!seatInfo.building_id}
            >
              <option value="">请选择楼层</option>
              {floors.map(floor => (
                <option key={floor.id} value={floor.id}>{floor.name}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="house_id">房间号</label>
            <select
              className="input-field"
              id="house_id"
              name="house_id"
              value={seatInfo.house_id?.toString() || ''}
              onChange={handleHouseChange}
              required
              disabled={!seatInfo.floor_id}
            >
              <option value="">请选择房间</option>
              {houses.map(house => (
                <option key={house.id} value={house.id}>{house.name}</option>
              ))}
            </select>
          </div>
          {seatInfo.house_type_id === 1 && (
            <div className="input-group">
              <label className="input-label" htmlFor="number">座位号</label>
              <select
                className="input-field"
                id="number"
                name="number"
                value={seatInfo.id?.toString() || ''}
                onChange={handleSeatChange}
                required
                disabled={!seatInfo.house_id}
              >
                <option value="">请选择座位</option>
                {seats.map(seat => (
                  <option key={seat.id} value={seat.id}>{seat.number} {seat.status === 0 ? '' : '(已占用:请联系相关老师)'}</option>
                ))}
              </select>
            </div>
          )}
          <div className="input-group">
            <label className="input-label" htmlFor="department_type_name">所属系部</label>
            <select
              className="input-field"
              id="department_type_name"
              name="department_type_name"
              value={seatInfo.department_type_name?.toString() || ''}
              onChange={handleInputChange}
              required
            >
              <option value="">请选择所属系部</option>
              {departmentTypes.map(type => (
                <option key={type.id} value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
          {seatInfo.house_type_id === 1 ? (
            <>
              <div className="input-group">
                <label className="input-label" htmlFor="student_type_name">学生类型</label>
                <select
                  className="input-field"
                  id="student_type_name"
                  name="student_type_name"
                  value={seatInfo.student_type_name?.toString() || ''}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">请选择学生类型</option>
                  {studentTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="student_name">学生姓名</label>
                <input
                  className="input-field"
                  id="student_name"
                  name="student_name"
                  type="text"
                  value={seatInfo.student_name?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="student_id">学生学号</label>
                <input
                  className="input-field"
                  id="student_id"
                  name="student_id"
                  type="text"
                  value={seatInfo.student_id?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="teacher_name">导师姓名</label>
                <input
                  className="input-field"
                  id="teacher_name"
                  name="teacher_name"
                  type="text"
                  value={seatInfo.teacher_name?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </>
          ) : (
            <>
              <div className="input-group">
                <label className="input-label" htmlFor="teacher_name">教师姓名</label>
                <input
                  className="input-field"
                  id="teacher_name"
                  name="teacher_name"
                  type="text"
                  value={seatInfo.teacher_name?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="teacher_id">教师工号</label>
                <input
                  className="input-field"
                  id="teacher_id"
                  name="teacher_id"
                  type="text"
                  value={seatInfo.teacher_id?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="research_direction">研究方向</label>
                <input
                  className="input-field"
                  id="research_direction"
                  name="research_direction"
                  type="text"
                  value={seatInfo.research_direction?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="gender">性别</label>
                <select
                  className="input-field"
                  id="gender"
                  name="gender"
                  value={seatInfo.gender?.toString() || ''}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">请选择性别</option>
                  <option value="男">男</option>
                  <option value="女">女</option>
                </select>
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="birthday">出生日期</label>
                <input
                  className="input-field"
                  id="birthday"
                  name="birthday"
                  type="date"
                  value={seatInfo.birthday?.toString() || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="is_graduate_supervisor">是否为研究生导师</label>
                <select
                  className="input-field"
                  id="is_graduate_supervisor"
                  name="is_graduate_supervisor"
                  value={seatInfo.is_graduate_supervisor?.toString() || ''}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">请选择</option>
                  <option value="true">是</option>
                  <option value="false">否</option>
                </select>
              </div>
            </>
          )}
          {seatInfo.house_type_id === 2 && (
            <>
              <div className="input-group">
                <label className="input-label" htmlFor="position_name">职务（选填）</label>
                <input
                  className="input-field"
                  id="position_name"
                  name="position_name"
                  type="text"
                  value={positionName}
                  onChange={(e) => setPositionName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label className="input-label" htmlFor="title_name">职称（选填）</label>
                <input
                  className="input-field"
                  id="title_name"
                  name="title_name"
                  type="text"
                  value={titleName}
                  onChange={(e) => setTitleName(e.target.value)}
                />
              </div>
            </>
          )}
          <button className="query-button" type="submit" disabled={isLoading}>
            {isLoading ? '提交中...' : (seatInfo.house_type_id === 1 ? '更新' : '添加')}
          </button>
        </form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ExamInfoPage;